<template>
    <div class="container">
        <b-modal title="Update Status" id="modal" no-close-on-esc hide-footer @hidden="model={}">
            <validation-observer v-slot="{handleSubmit}" ref="formValidator">
                <b-form role="form" @submit.prevent="handleSubmit(onSubmit)">
                    <div class="row">
                        <div class="col-md-12 mt-4">
                            <b-form-select name="status" label="status" v-model="model.status" :rules="rules.status"
                                           :options="options"></b-form-select>
                        </div>
                        <div class="col-md-12 d-none">
                            <validated-input name="id" label="id" v-model="model.id" :rules="rules.id"/>
                        </div>
                        <div class="col-md-12 mt-4">
                            <b-button type="submit" variant="success">
                                update
                            </b-button>
                        </div>

                    </div>
                </b-form>
            </validation-observer>
        </b-modal>
        <div class="row mt-4">
            <div class="col-md-12 text-center" v-if="isEmpty">
                <img src="../../../assets/img/empty-cart.svg" style=" max-width: 50%; max-height: 80%;"
                     class="mb-5">
                <h1>You have no items in your shopping cart.</h1>
                <button type="button"
                        class="btn base-button btn-outline-info mt-2"
                        @click="Redirect">Continue Shopping</button>
            </div>
            <div class="col-md-12">
                <div class="card card-pricing border-0 text-center mb-4" v-if="!isEmpty">
                    <div class="card-body">
                        <div class="mt-3 row mb-4 text-center">
                            <b-col>
                                <span class="font-weight-600">Shop - {{ cart[0].name }}</span>
                            </b-col>

                        </div>
<div class="responsive-table">
                        <table class="table vuetable">
                            <thead>
                            <tr>
                                <th>Product</th>
                                <th>Quantity</th>
                                <th>Unit Price</th>
                                <th>Total Price</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(product, index) in cart_items" v-bind:key="index">
                                <td>{{ product.name }}</td>
                                <td>
                                    <div>
                                        <div>
                                            <b-button variant="info"
                                                      class="btn btn-sm btn-icon-only rounded-circle m-1"
                                                      @click.prevent="addToCart(product, 'minus')">-
                                            </b-button>
                                            <b v-html="product.quantity"></b>
                                            <b-button variant="info"
                                                      class="btn btn-sm btn-icon-only rounded-circle m-1"
                                                      @click.prevent="addToCart(product, 'plus')">+
                                            </b-button>
                                            <b-button variant="danger"
                                                      class="btn btn-sm btn-icon-only rounded-circle m-1"
                                                      @click.prevent="removeCart(product)">
                                                <i class="simple-icon-trash"></i>
                                            </b-button>
                                        </div>
                                    </div>
                                </td>
                                <td>{{ product.unit_price }}</td>
                                <td>{{ product.total_price }}</td>
                            </tr>
                            <tr>
                                <td colspan="3">Total</td>
                                <td>{{ cart[0].total_amount }}</td>
                            </tr>
                            </tbody>
                        </table>
</div>
                        <div class="float-right">
                            <b-button variant="primary" title="add to cart"
                                      class="btn btn-sm" @click="placeOrder()">
                                Place Order
                            </b-button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

</template>

<script>
import axios from 'secure-axios';
import urls from '@data/urls';
import { mapGetters } from 'vuex';

export default {
    name: 'CartDetails',
    components: {},
    data () {
        return {
            data: '',
            cart: [],
            cart_items: [],
            model: {
                status: '',
                id: ''
            },
            rules: {
                status: {
                    required: true
                },
                id: {
                    required: true
                }
            }
        };
    },
    beforeMount () {
        this.loadData();
    },
    methods: {
        openModal () {
            this.$bvModal.show('modal');
        },
        setData (response) {
            console.log(response.data.data.cart);
            if (response.data) {
                this.cart = response.data.data.cart;
                this.cart_items = response.data.data.cart_items;
            }
        },

        async onSubmit () {
            let url;
            if (this.model.id) {
                url = urls.shopOrder.update;
            } else {
                url = urls.shopOrder.create;
            }

            const response = await axios.form(url, this.model);
            if (response.data.error) {
                this.$refs.formValidator.setErrors(response.data.errors);
            } else {
                this.setData(response);
                this.$bvModal.hide('modal');
            }
        },
        async loadData () {
            const response = await axios.get(urls.cart.cartdetails + '?id=' + this.$route.params.id);
            this.setData(response);
        },

        async UpdateStatus () {
            this.model = {
                id: this.$route.params.id
            };
            this.openModal();
        },

        Redirect () {
            const id = this.currentUser.defaultshop;
            this.$router.push({ path: '/user/shops/' + id + '/products/' });
        },
        placeOrder () {
            const id = this.$route.params.id;
            this.$router.push({ path: '/user/' + id + '/address-list/' });
        },
        async addToCart (rowData, action) {
            console.log('rowData =>', rowData);
            if (action === 'first') {
                rowData.quantity = 1;
            } else if (action === 'plus') {
                let qnty = parseInt(rowData.quantity);
                qnty = qnty + 1;
                rowData.quantity = qnty;
            } else {
                rowData.quantity -= 1;
            }
            if (rowData.quantity === 0) {
                this.removeCart(rowData);
            } else {
                console.log('here =>', this.currentUser);
                const postData = {
                    user_id: this.currentUser.id,
                    product_id: rowData.product_id,
                    quantity: rowData.quantity
                };
                console.log('here =>', postData);
                const response = await axios.form(urls.cart.addCard, postData);
                console.log('here =>', response.data);
                if (response.data.error) {
                // this.$refs.formValidator.setErrors(response.data.errors);
                } else {
                    this.setData(response);
                }
            }
            // this.openModal();
        },
        async removeCart (rowData) {
            const postData = {
                user_id: this.currentUser.id,
                product_id: rowData.product_id
            };
            console.log('postdata' + postData);
            const response = await axios.form(urls.cart.removeCart, postData);
            console.log(response);
            console.log(response.data);
            if (response.data.error === true) {
                // this.$refs.formValidator.setErrors(response.data.errors);
            } else {
                rowData.count = 0;
                this.setData(response);
                // this.$bvModal.hide('modal');
            }
        }

    },
    computed: {
        isEmpty: function () {
            return this.cart.length <= 0;
            // return true;
        },
        ...mapGetters(['currentUser'])
    }
};
</script>

<style scoped>

</style>
